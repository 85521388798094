/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
 import "./src/styles/style.scss"
//  import React from 'react'

// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
//  export const wrapRootElement = ({ element }) => {
//      return (
        
      
//              {element}
            
//     )
//  }